import React from 'react';
import { User, MessageSquare, Mail, CreditCard, Tag, Briefcase, HardDrive, Shield, UserCheck, Layout, BarChart2, ExternalLink, Link, MessageCircle, FileText, Laptop, HeadphonesIcon, MessagesSquare, Cloud, PieChart, TestTubes, Globe } from "lucide-react";
import '../css/iubenda.css';

const services = [
  {
    icon: User,
    title: "Accesso agli account su servizi terzi",
    items: [
      {
        name: "Accesso all'account Facebook",
        description: "Permessi: Chat; Dati di utilizzo"
      }
    ]
  },
  {
    icon: MessageSquare,
    title: "Commento dei contenuti",
    items: [
      {
        name: "Facebook Comments",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      }
    ]
  },
  {
    icon: Mail,
    title: "Contattare l'Utente",
    items: [
      {
        name: "Mailing list o newsletter",
        description: "Dati Personali: email; nome"
      },
      {
        name: "Modulo di contatto",
        description: "Dati Personali: cognome; data di nascita; email; nome"
      },
      {
        name: "Modulo di contatto SumoMe",
        description: "Dati Personali: Dati di utilizzo; email; Strumento di Tracciamento"
      }
    ]
  },
  {
    icon: CreditCard,
    title: "Gestione dei pagamenti",
    items: [
      {
        name: "PayPal",
        description: "Dati Personali: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio"
      }
    ]
  },
  {
    icon: Tag,
    title: "Gestione dei tag",
    items: [
      {
        name: "Google Tag Manager",
        description: "Dati Personali: Dati di utilizzo"
      }
    ]
  },
  {
    icon: Briefcase,
    title: "Gestione di landing page e pagine di invito",
    items: [
      {
        name: "Unbounce",
        description: "Dati Personali: Dati di utilizzo; email; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio"
      }
    ]
  },
  {
    icon: HardDrive,
    title: "Hosting ed infrastruttura backend",
    items: [
      {
        name: "DigitalOcean",
        description: "Dati Personali: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio"
      },
      {
        name: "Vercel",
        description: "Dati Personali: Dati di utilizzo; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio"
      }
    ]
  },
  {
    icon: Shield,
    title: "Protezione dallo SPAM",
    items: [
      {
        name: "Google reCAPTCHA",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      }
    ]
  },
  {
    icon: UserCheck,
    title: "Registrazione ed autenticazione",
    items: [
      {
        name: "Facebook Authentication",
        description: "Dati Personali: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio"
      }
    ]
  },
  {
    icon: Layout,
    title: "Statistica",
    items: [
      {
        name: "Google Analytics",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      },
      {
        name: "Meta Events Manager",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      }
    ]
  },
  {
    icon: BarChart2,
    title: "Visualizzazione di contenuti da piattaforme esterne",
    items: [
      {
        name: "Google Fonts",
        description: "Dati Personali: Dati di utilizzo; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio"
      },
      {
        name: "Widget Video YouTube",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      },
      {
        name: "Forma LMS",
        description: "Dati Personali: Dati di utilizzo;"
      }
    ]
  },
  {
    icon: ExternalLink,
    title: "Remarketing e behavioral targeting",
    items: [
      {
        name: "Facebook Remarketing",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      },
      {
        name: "Google Ads Remarketing",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      }
    ]
  },
  {
    icon: Link,
    title: "Collegamento Dati",
    items: [
      {
        name: "Zapier",
        description: "Dati Personali: email"
      }
    ]
  },
  {
    icon: MessageCircle,
    title: "Gestione contatti e invio di messaggi",
    items: [
      {
        name: "SendinBlue Email",
        description: "Dati Personali: Dati di utilizzo; email; Strumento di Tracciamento"
      }
    ]
  },
  {
    icon: FileText,
    title: "Gestione della raccolta dati e dei sondaggi online",
    items: [
      {
        name: "Inserzioni per acquisizione contatti di Facebook",
        description: "Dati Personali: Dati comunicati durante l'utilizzo del servizio"
      }
    ]
  },
  {
    icon: Laptop,
    title: "Gestione delle attività relative alla produttività",
    items: [
      {
        name: "Gmail",
        description: "Dati Personali: cognome; data di nascita; email; nome; numero di telefono; username"
      }
    ]
  },
  {
    icon: HeadphonesIcon,
    title: "Gestione delle richieste di supporto e contatto",
    items: [
      {
        name: "WhatsApp Business assistenza clienti",
        description: "Dati Personali: nazione; nome; numero di telefono"
      }
    ]
  },
  {
    icon: MessagesSquare,
    title: "Interazione con le piattaforme di live chat",
    items: [
      {
        name: "Facebook Messenger Customer Chat e LiveChat Widget",
        description: "Dati Personali: Dati comunicati durante l'utilizzo del servizio; Dati di utilizzo; Strumento di Tracciamento"
      }
    ]
  },
  {
    icon: Cloud,
    title: "Ottimizzazione e distribuzione del traffico",
    items: [
      {
        name: "Cloudflare",
        description: "Dati Personali: Strumenti di Tracciamento; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio"
      }
    ]
  },
  {
    icon: ExternalLink,
    title: "Pubblicità",
    items: [
      {
        name: "Facebook Audience Network",
        description: "Dati Personali: Dati di utilizzo; identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o identificatore IDFA, per esempio); Strumento di Tracciamento"
      },
      {
        name: "Pubblico simile di Facebook",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      },
      {
        name: "360 E-Com, Monitoraggio conversioni di Meta ads (pixel di Meta) e Microsoft Advertising",
        description: "Dati Personali: Dati di utilizzo; Strumenti di Tracciamento"
      }
    ]
  },
  {
    icon: Globe,
    title: "Servizi di piattaforma e hosting",
    items: [
      {
        name: "WordPress.com",
        description: "Dati Personali: varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio"
      }
    ]
  },
  {
    icon: PieChart,
    title: "Statistica",
    items: [
      {
        name: "Google Analytics (Universal Analytics) e Wordpress Stat",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      },
      {
        name: "Google Analytics 4",
        description: "Dati Personali: Dati di utilizzo; numero di Utenti; statistiche delle sessioni; Strumenti di Tracciamento"
      },
      {
        name: "Funzionalità di generazione dei rapporti sulla pubblicità in Google Analytics",
        description: "Dati Personali: identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o identificatore IDFA, per esempio); Strumenti di Tracciamento; varie tipologie di Dati secondo quanto specificato dalla privacy policy del servizio"
      }
    ]
  },
  {
    icon: TestTubes,
    title: "Test di performance di contenuti e funzionalità (A/B testing)",
    items: [
      {
        name: "Google Optimize e Google Optimize 360",
        description: "Dati Personali: Dati di utilizzo; Strumento di Tracciamento"
      }
    ]
  }
];

const Iubenda = () => {
  return (
    <div className="iubenda-privacy-policy">
      <Header />
      <ServicesSection />
      <AdInfoSection />
      <ContactSection />
      <Footer />
    </div>
  );
};

const Header = () => (
  <>
    <h1 className="iubenda-privacy-policy__title">Privacy Policy di www.pokermagia.com</h1>
    <p className="iubenda-privacy-policy__subtitle">Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.</p>
  </>
);

const ServicesSection = () => (
  <>
    <h2 className="iubenda-privacy-policy__section-title">Dati Personali trattati per le seguenti finalità e utilizzando i seguenti servizi:</h2>
    <div className="iubenda-privacy-policy__services-grid">
      {services.map((service, index) => (
        <ServiceItem key={index} service={service} />
      ))}
    </div>
  </>
);

const ServiceItem = ({ service }) => (
  <div className="iubenda-privacy-policy__service-item">
    <div className="iubenda-privacy-policy__service-icon">
      <service.icon />
    </div>
    <div className="iubenda-privacy-policy__service-content">
      <h3 className="iubenda-privacy-policy__service-title">{service.title}</h3>
      {service.items.map((item, itemIndex) => (
        <ServiceDetail key={itemIndex} item={item} />
      ))}
    </div>
  </div>
);

const ServiceDetail = ({ item }) => (
  <div className="iubenda-privacy-policy__service-detail">
    <p className="iubenda-privacy-policy__service-name">{item.name}</p>
    <p className="iubenda-privacy-policy__service-description">{item.description}</p>
  </div>
);

const AdInfoSection = () => (
  <>
    <h2 className="iubenda-privacy-policy__section-title">Informazioni su come disattivare gli annunci pubblicitari basati sugli interessi</h2>
    <p className="iubenda-privacy-policy__text iubenda-privacy-policy__text--large-margin">
      Oltre a qualsiasi funzione di opt-out fornita da uno qualsiasi dei servizi elencati in questo documento, gli Utenti possono leggere di più su come disattivare gli annunci pubblicitari basati sugli interessi nell'apposita sezione della Cookie Policy.
    </p>
  </>
);

const ContactSection = () => (
  <>
    <h2 className="iubenda-privacy-policy__section-title">Informazioni di contatto</h2>
    <div className="iubenda-privacy-policy__contact-info">
      <User className="iubenda-privacy-policy__contact-icon" />
      <div>
        <h3 className="iubenda-privacy-policy__contact-title iubenda-privacy-policy__titolare-title">Titolare del Trattamento dei Dati:</h3>
        <div className="iubenda-privacy-policy__address">
          <p>BITMOBI LTD</p>
          <p>135/9 Triq In-Naxxar,</p>
          <p>SGN 9030, San Gwann</p>
          <p>Malta</p>
        </div>
        <p className="iubenda-privacy-policy__email">
          <strong>Indirizzo email del Titolare:</strong> admin@bitmobi.eu
        </p>
      </div>
    </div>
  </>
);

const Footer = () => (
  <>
    <p className="iubenda-privacy-policy__last-modified">Ultima modifica: 26 settembre 2024</p>
    <p className="iubenda-privacy-policy__disclaimer">
      iubenda ospita questo contenuto e raccoglie solo i Dati Personali strettamente necessari alla sua fornitura.
    </p>
  </>
);

export default Iubenda;