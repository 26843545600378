import React from 'react';
import { User, MessageSquare, Mail, CreditCard, Tag, Briefcase, HardDrive, Shield, UserCheck, Layout, BarChart2, ExternalLink, Link, MessageCircle, FileText, Laptop, HeadphonesIcon, MessagesSquare, Cloud, PieChart, TestTubes, Globe } from "lucide-react";
import '../css/iubenda.css';

const services = [
  {
    icon: User,
    title: "Acceso a cuentas de terceros",
    items: [
      {
        name: "Acceso a la cuenta de Facebook",
        description: "Permisos: Chat; Datos de uso"
      }
    ]
  },
  {
    icon: MessageSquare,
    title: "Comentarios de contenidos",
    items: [
      {
        name: "Comentarios de Facebook",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: Mail,
    title: "Contactar al Usuario",
    items: [
      {
        name: "Lista de correo o boletín",
        description: "Datos Personales: correo electrónico; nombre"
      },
      {
        name: "Formulario de contacto",
        description: "Datos Personales: apellido; fecha de nacimiento; correo electrónico; nombre"
      },
      {
        name: "Formulario de contacto SumoMe",
        description: "Datos Personales: Datos de uso; correo electrónico; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: CreditCard,
    title: "Gestión de pagos",
    items: [
      {
        name: "PayPal",
        description: "Datos Personales: varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: Tag,
    title: "Gestión de etiquetas",
    items: [
      {
        name: "Google Tag Manager",
        description: "Datos Personales: Datos de uso"
      }
    ]
  },
  {
    icon: Briefcase,
    title: "Gestión de páginas de destino y páginas de invitación",
    items: [
      {
        name: "Unbounce",
        description: "Datos Personales: Datos de uso; correo electrónico; varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: HardDrive,
    title: "Alojamiento e infraestructura de backend",
    items: [
      {
        name: "DigitalOcean",
        description: "Datos Personales: varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      },
      {
        name: "Vercel",
        description: "Datos Personales: Datos de uso; varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: Shield,
    title: "Protección contra SPAM",
    items: [
      {
        name: "Google reCAPTCHA",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: UserCheck,
    title: "Registro y autenticación",
    items: [
      {
        name: "Autenticación de Facebook",
        description: "Datos Personales: varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: Layout,
    title: "Estadísticas",
    items: [
      {
        name: "Google Analytics",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      },
      {
        name: "Meta Events Manager",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: BarChart2,
    title: "Visualización de contenido de plataformas externas",
    items: [
      {
        name: "Google Fonts",
        description: "Datos Personales: Datos de uso; varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      },
      {
        name: "Widget de Video YouTube",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: ExternalLink,
    title: "Remarketing y targeting conductual",
    items: [
      {
        name: "Remarketing de Facebook",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      },
      {
        name: "Remarketing de Google Ads",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: Link,
    title: "Conexión de Datos",
    items: [
      {
        name: "Zapier",
        description: "Datos Personales: correo electrónico"
      }
    ]
  },
  {
    icon: MessageCircle,
    title: "Gestión de contactos y envío de mensajes",
    items: [
      {
        name: "SendinBlue Email",
        description: "Datos Personales: Datos de uso; correo electrónico; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: FileText,
    title: "Gestión de la recopilación de datos y encuestas en línea",
    items: [
      {
        name: "Anuncios para adquisición de contactos de Facebook",
        description: "Datos Personales: Datos comunicados durante el uso del servicio"
      }
    ]
  },
  {
    icon: Laptop,
    title: "Gestión de actividades relacionadas con la productividad",
    items: [
      {
        name: "Gmail",
        description: "Datos Personales: apellido; fecha de nacimiento; correo electrónico; nombre; número de teléfono; nombre de usuario"
      }
    ]
  },
  {
    icon: HeadphonesIcon,
    title: "Gestión de solicitudes de soporte y contacto",
    items: [
      {
        name: "WhatsApp Business atención al cliente",
        description: "Datos Personales: país; nombre; número de teléfono"
      }
    ]
  },
  {
    icon: MessagesSquare,
    title: "Interacción con plataformas de chat en vivo",
    items: [
      {
        name: "Facebook Messenger Customer Chat y LiveChat Widget",
        description: "Datos Personales: Datos comunicados durante el uso del servicio; Datos de uso; Herramienta de Seguimiento"
      }
    ]
  },
  {
    icon: Cloud,
    title: "Optimización y distribución del tráfico",
    items: [
      {
        name: "Cloudflare",
        description: "Datos Personales: Herramientas de Seguimiento; varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: ExternalLink,
    title: "Publicidad",
    items: [
      {
        name: "Facebook Audience Network",
        description: "Datos Personales: Datos de uso; identificadores únicos de dispositivos para publicidad (Google Advertiser ID o identificador IDFA, por ejemplo); Herramienta de Seguimiento"
      },
      {
        name: "Público similar de Facebook",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      },
      {
        name: "360 E-Com, Seguimiento de conversiones de Meta ads (pixel de Meta) y Microsoft Advertising",
        description: "Datos Personales: Datos de uso; Herramientas de Seguimiento"
      }
    ]
  },
  {
    icon: Globe,
    title: "Servicios de plataforma y alojamiento",
    items: [
      {
        name: "WordPress.com",
        description: "Datos Personales: varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: PieChart,
    title: "Estadística",
    items: [
      {
        name: "Google Analytics (Universal Analytics) y Wordpress Stat",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      },
      {
        name: "Google Analytics 4",
        description: "Datos Personales: Datos de uso; número de Usuarios; estadísticas de sesiones; Herramientas de Seguimiento"
      },
      {
        name: "Funcionalidad de generación de informes sobre publicidad en Google Analytics",
        description: "Datos Personales: identificadores únicos de dispositivos para publicidad (Google Advertiser ID o identificador IDFA, por ejemplo); Herramientas de Seguimiento; varios tipos de Datos según lo especificado en la política de privacidad del servicio"
      }
    ]
  },
  {
    icon: TestTubes,
    title: "Pruebas de rendimiento de contenido y funcionalidades (A/B testing)",
    items: [
      {
        name: "Google Optimize y Google Optimize 360",
        description: "Datos Personales: Datos de uso; Herramienta de Seguimiento"
      }
    ]
  }
];

const IubendaES = () => {
  return (
    <div className="iubenda-privacy-policy">
      <Header />
      <ServicesSection />
      <AdInfoSection />
      <ContactSection />
      <Footer />
    </div>
  );
};

const Header = () => (
  <>
    <h1 className="iubenda-privacy-policy__title">Política de Privacidad de www.pokermagia.com</h1>
    <p className="iubenda-privacy-policy__subtitle">Esta Aplicación recoge algunos Datos Personales de sus Usuarios.</p>
  </>
);

const ServicesSection = () => (
  <>
    <h2 className="iubenda-privacy-policy__section-title">Datos Personales tratados para las siguientes finalidades y utilizando los siguientes servicios:</h2>
    <div className="iubenda-privacy-policy__services-grid">
      {services.map((service, index) => (
        <ServiceItem key={index} service={service} />
      ))}
    </div>
  </>
);

const ServiceItem = ({ service }) => (
  <div className="iubenda-privacy-policy__service-item">
    <div className="iubenda-privacy-policy__service-icon">
      <service.icon />
    </div>
    <div className="iubenda-privacy-policy__service-content">
      <h3 className="iubenda-privacy-policy__service-title">{service.title}</h3>
      {service.items.map((item, itemIndex) => (
        <ServiceDetail key={itemIndex} item={item} />
      ))}
    </div>
  </div>
);

const ServiceDetail = ({ item }) => (
  <div className="iubenda-privacy-policy__service-detail">
    <p className="iubenda-privacy-policy__service-name">{item.name}</p>
    <p className="iubenda-privacy-policy__service-description">{item.description}</p>
  </div>
);

const AdInfoSection = () => (
  <>
    <h2 className="iubenda-privacy-policy__section-title">Información sobre cómo desactivar los anuncios publicitarios basados en intereses</h2>
    <p className="iubenda-privacy-policy__text iubenda-privacy-policy__text--large-margin">
      Además de cualquier función de exclusión voluntaria proporcionada por cualquiera de los servicios enumerados en este documento, los Usuarios pueden obtener más información sobre cómo desactivar los anuncios publicitarios basados en intereses en la sección correspondiente de la Política de Cookies.
    </p>
  </>
);

const ContactSection = () => (
  <>
    <h2 className="iubenda-privacy-policy__section-title">Información de contacto</h2>
    <div className="iubenda-privacy-policy__contact-info">
      <User className="iubenda-privacy-policy__contact-icon" />
      <div>
        <h3 className="iubenda-privacy-policy__contact-title iubenda-privacy-policy__titolare-title">Titular del Tratamiento de Datos:</h3>
        <div className="iubenda-privacy-policy__address">
          <p>BITMOBI LTD</p>
          <p>135/9 Triq In-Naxxar,</p>
          <p>SGN 9030, San Gwann</p>
          <p>Malta</p>
        </div>
        <p className="iubenda-privacy-policy__email">
          <strong>Dirección de correo electrónico del Titular:</strong> admin@bitmobi.eu
        </p>
      </div>
    </div>
  </>
);

const Footer = () => (
  <>
    <p className="iubenda-privacy-policy__last-modified">Última modificación: 26 de septiembre de 2024</p>
    <p className="iubenda-privacy-policy__disclaimer">
      iubenda aloja este contenido y solo recopila los Datos Personales estrictamente necesarios para su provisión.
    </p>
  </>
);

export default IubendaES;